(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/tipsen-empty-notice/assets/empty-notice.js') >= 0) return;  svs.modules.push('/components/sport/tipsen-empty-notice/assets/empty-notice.js');
"use strict";


const {
  ReactIcon
} = svs.ui;
const EmptyNotice = _ref => {
  let {
    children,
    branding
  } = _ref;
  let color;
  let fontColor = 'fc-navy';
  switch (branding) {
    case 'matchen':
    case 'bomben':
    case 'challenge':
    case 'powerplay':
      color = 'fc-oddset-header';
      break;
    case 'europatipset':
      color = 'fc-europatipset';
      break;
    case 'topptipset':
      color = 'fc-topptipset';
      break;
    case 'stryktipset':
      color = 'fc-stryktipset';
      break;
    case 'hvs':
    case 'fulltraff':
      color = 'fc-fulltraff';
      fontColor = 'fc-fulltraff-400';
      break;
    default:
      color = 'fc-navy';
      break;
  }
  return React.createElement("div", {
    className: "empty-notice"
  }, React.createElement(ReactIcon, {
    className: "empty-notice-icon",
    color: color,
    icon: "rest",
    size: "1000"
  }), React.createElement("p", {
    className: "".concat(fontColor, " f-800 empty-notice-text")
  }, children));
};
setGlobal('svs.component.tipsenEmptyNotice.EmptyNotice', EmptyNotice);

 })(window);